

//Registration

/*Account Section Starts Here*/
.account-wrapper {
    border-radius: 8px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
    background: $primary-color;
    padding: 60px 80px;
    text-align: center;
    max-width: 545px;
    margin: 0 auto 15px;

    .title {
        @extend %mb-30;
    }

    @include breakpoint(max-md) {
        padding: 50px 40px;
    }

    @media screen and (max-width:499px) {
        padding: 40px 15px;
    }

    .cate {
        font-style: normal;

        a {
            font-weight: 600;
            color: $theme-color;

            &:hover {
                color: $theme-color;
            }
        }
    }
}

.account-bottom {
    .subtitle {
        margin-bottom: 10px;

        @include breakpoint(sm) {
            font-size: 20px;
        }
    }
    .match-social-list {
        li {
            margin: 5px;
        }
    }
}

.account-form {
    .form-group {
        margin-bottom: 15px;
        label {
            @extend %mb-10;
        }
        input {
            width: 100%;
            @include border-radius(4px);
            background-color: $white-color;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
            padding: 10px 15px;
            border: none;
            line-height: 36px;
            &::placeholder {
                color: rgba($white-color, $alpha: .50);
            }
        }

        .checkgroup {
            @extend %flex;
            flex-wrap: nowrap;
            align-items: center;

            input {
                &[type="checkbox"] {
                    width: 18px;
                    height: 18px;
                    margin-right: 15px;

                    @include breakpoint(max-sm) {
                        width: 14px;
                        height: 14px;
                        margin-right: 8px;
                    }
                }
            }

            label {
                margin: 0;
                color: $white-color;

                @include breakpoint(max-sm) {
                    font-size: 14px;
                }
            }
        }

        a {
            color: $white-color;
            text-decoration: underline;

            @include breakpoint(max-sm) {
                font-size: 14px;
            }

            &:hover {
                color: $theme-color
            }
        }

        button {
            margin-top: 34px;
            width: 100%;
            border: none;
            outline: none;

            @include breakpoint(max-sm) {
                margin-top: 24px;
            }
        }
    }
    .select-gender {
        
    }
    .birth-select {
        @extend %flex;
        @extend %mb-15;
        justify-content: space-between;
        label {
            width: 100%;
            @extend %mb-10;
        }
        select {
            width: calc(100% / 3 - 10px);
        }
    }
    
    select {
        @include border-radius(4px);
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
        padding: 10px 15px;
        border: none;
        line-height: 36px;
        outline: none;
        box-shadow: none;
        &:focus {
            border-color: none;
            box-shadow: none;
        }
    }
    .country, .provency-group {
        @extend %mb-15;
        label {
            @extend %mb-10;
        }
    }
    .number-group {
        @extend %flex;
        justify-content: space-between;
        label {
            width: 100%;
            @extend %mb-10;
        }
        select {
            width: 80px;
        }
        input {
            width: calc(100% - 95px);
            background: $white-color;
        }
    }
}

.or {
    display: block;
    margin: 24px auto 40px;
    width: 36px;
    height: 36px;
    line-height: 36px;
    @include border-radius(50%);
    background: $theme-color;
    position: relative;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    color: $white-color;

    span {
        position: relative;
        z-index: 1;
    }

    &::before,
    &::after {
        position: absolute;
        content: "";
        top: 50%;
        left: 50%;
        background: $theme-color;
        @include border-radius(50%);
        background: rgba($color:$theme-color, $alpha:.3);
        width: 100%;
        height: 100%;
        animation: pulse 2s linear infinite;
        -webkit-animation: pulse 2s linear infinite;
        -moz-animation: pulse 2s linear infinite;
        opacity: 0;
    }

    &::before {
        animation-delay: 1s;
        -webkit-animation-delay: 1s;
        -moz-animation-delay: 1s;
    }
}

@keyframes pulse {
    0% {
        @include transform(translate(-50%, -50%)scale(1));
        opacity: 1;
    }

    90% {
        opacity: .5;
        @include transform(translate(-50%, -50%)scale(1.8));
    }

    100% {
        opacity: 0;
        @include transform(translate(-50%, -50%)scale(2));
    }
}

//floating keyframes
@keyframes floating {
    0% {
        transform: translate(0, -10px);
    }

    50% {
        transform: translate(0, 10px);
    }

    100% {
        transform: translate(0, -10px);
    }
}