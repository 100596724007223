.gallery-item {
    text-align: center;
    border-width: 8px;
    box-shadow: 0px 2px 4px 0px rgba(79, 63, 82, 0.06);
    max-width: 400px;
    margin: 0 auto;
    @extend %rel;
    &:hover {
        &::after, .gallery-content {
            opacity: 1;
        }
        .gallery-thumb {
            img {
                @include transform(scale(1.1));
            }
        }
    }
    &::after {
        width: 96%;
        height: 96%;
        top: 50%;
        left: 50%;
        background: rgba($color: $black-color, $alpha:.5);
        opacity: .7;
        @extend %abs;
        @include transform(translate(-50%, -50%));
        opacity: 0;
        @include transition($transition);
        @include border-radius(8px);
    }
    .gallery-thumb {
        overflow: hidden;
        @include border-radius(8px);
        img {
            width: 100%;
            @include transition($transition);
        }
        a {
            display: block;
        }
    }
    .gallery-content {
        width: 100%;
        z-index: 1;
        @extend %center;
        opacity: 0;
        @include transition($transition);
        .title {
            font-size: 20px;
            margin-bottom: 5px;
            a {
                display: block;
            }
        }
        span {}
        .img-pop {
            width: 50px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            color: $white-color;
            background: $theme-color;
            @include border-radius(50%);
            margin-bottom: 15px;
        }
    }
}
