//ripple keyframes
@keyframes ripple {
    from {
        opacity: 1;
        transform: scale3d(0.75, 0.75, 1);
    }

    to {
        opacity: 0;
        transform: scale3d(1.5, 1.5, 1);
    }
}


@keyframes pluse_animate {
    0% {
        opacity: 1;
        -webkit-transform: translate(-50%, -50%) scale(0.3);
        transform: translate(-50%, -50%) scale(0.3);
    }

    100% {
        opacity: 0;
        -webkit-transform: translate(-50%, -50%) scale(2);
        transform: translate(-50%, -50%) scale(2);
    }
}

//floating keyframes
@keyframes rotating {
    from {
        transform: rotateY(0deg);
    }

    to {
        transform: rotateY(360deg);
    }
}



@keyframes lab_zoominout {
    0% {
        transform: scale(.85);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(.85);
    }
}
