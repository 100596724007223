//pricing-item
.price-item {
    @include transition($transition);

    .price-item-inner {
        @extend %text-center;
        // @extend %bg-primary;
        background: $primary-color;
        border-radius: 10px;
        @extend %hidden;
        @include transition($transition);

        .price-top {
            padding-top: 40px;
            padding-bottom: 100px;
            background: url(../images/pricing-plan/pricing-plan.svg);
            background-repeat: no-repeat;
            background-size: cover;

            h2 {
                @extend %mb-5;
            }

            p {
                max-width: 238px;
                margin: 0 auto;
            }
        }

        .price-bottom {
            padding-bottom: 40px;

            ul {

                li {
                    padding: 7px 10px;
                    @extend %flex;
                    @extend %align-items-center;
                    @extend %justify-center;

                    i {
                        @extend %mr-5;
                    }

                    p {
                        @extend %mb-0;
                    }
                }
            }

            .purchase-btn {
                margin-top: 50px;
                @extend %bg-theme;
                @extend %d-inline-block;
                line-height: 56px;
                padding: 0 3rem;
                @extend %border-radius-2;
                @include transition($transition);

                &:hover {
                    box-shadow: 0px 10px 10px 0px rgba(224, 49, 77, 0.2);
                    transform: translateY(-3px);
                }
            }
        }
    }

    &:hover,
    &.active {
        .price-item-inner {
            background: $white-color;

            .price-bottom {
                color: $primary-color;
            }
        }

        @include breakpoint(lg) {
            transform: translateY(-30px);
        }
    }
}