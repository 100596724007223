// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

.footer-section {
    background-color: $primary-color;
    background-size: cover;
    @include breakpoint(lg) {
        position: relative;
    }

    //top Footer part
    .footer-top {
        @include breakpoint(max-lg) {
            padding-top: 80px;
            padding-bottom: 80px;
        }

        @include breakpoint(lg) {
            border-bottom: 1px solid rgba($white-color, $alpha: .10);
            position: absolute;
            width: 100%;
            top: 0;
            z-index: 1;
            .col-12 {
                border-left: 1px solid rgba($white-color, $alpha: .10);

                &:last-child {
                    border-right: 1px solid rgba($white-color, $alpha: .10);
                }
            }
        }

        .footer-top-item {
            padding: 15px;

            @include breakpoint(xl) {
                padding: 40px 20px;
            }

            @include breakpoint(max-lg) {
                border: 1px solid rgba($white-color, $alpha: .10);
            }

            .lab-inner {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                flex-direction: column;
                justify-content: center;

                @include breakpoint(xl) {
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                }

                .lab-thumb {
                    margin-bottom: 15px;

                    @include breakpoint(xl) {
                        margin-bottom: 0;
                        margin-right: 15px;
                    }
                }

                .lab-content {
                    text-align: center;

                    span {
                        color: $white-color;
                    }
                }
            }
        }
    }

    //Middle footer part
    .footer-middle {
        @extend %rel;
        background-size: cover;
        background-position: center;

        .footer-middle-item-wrapper {
            @extend %rel;
            z-index: 9;

            .footer-middle-item {
                margin-bottom: 40px;

                .fm-item-title {
                    h4 {
                        margin-bottom: 25px;
                    }
                }

                .fm-item-content {
                    .footer-abt-img {
                        margin-bottom: 15px;
                    }

                    .fm-item-widget {
                        margin-bottom: 12px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        .lab-inner {
                            @extend %flex;
                            align-items: center;

                            .lab-thumb {
                                width: 80px;
                                overflow: hidden;

                                a {
                                    img {
                                        @include transition($transition);
                                    }
                                }
                            }

                            .lab-content {
                                width: calc(100% - 80px);
                                @extend %pl-20;

                                h6 {
                                    color: $white-color;
                                    font-size: 16px;
                                    @extend %text-clamp-2;
                                    font-weight: 600;
                                    line-height: 1.5;
                                    @extend %mb-0;    
                                }

                                p {
                                    margin: 0px;
                                    font-size: $fs-small;
                                }
                                .rating {
                                    i {
                                        @extend %theme-color;
                                    }
                                }
                            }
                        }

                        &:hover {
                            .lab-thumb {
                                a {
                                    img {
                                        transform: scale(1.1) rotate(-5deg);
                                    }
                                }
                            }

                            .lab-content {
                                h6 {
                                    color: $theme-color;
                                }
                            }
                        }
                    }
                }
                .match-social-list {
                    li {
                        margin: 5px;
                    }
                }
            }

            .footer-middle-item-3 {
                .fm-item-title {
                    h4 {
                        margin-bottom: 25px;
                    }
                }

                .fm-item-content {
                    p {
                        margin-bottom: 25px;
                    }

                    form {
                        .form-group {
                            input {
                                border: none;
                                @extend %border-radius-2;
                                padding: 13px 15px;
                                box-shadow: none;
                                background: $primary-color;
                                color: $white-color;
                                &::placeholder {
                                    color: rgba($white-color, $alpha: .85);
                                }
                            }
                        }

                        button,
                        .lab-btn {
                            outline: none;
                            border: none;
                            margin-top: 15px;
                            @extend %bg-theme;
                            @include border-radius(2px);
                            line-height: 56px;
                            i {
                                font-size: 24px;
                                margin-left: 5px;
                            }
                            &::after {
                                // background-color: rgba($white-color, $alpha: .9);
                            }
                        }
                    }
                }
            }
        }
    }

    //footer-bottom
    .footer-bottom {
        border-top: 1px solid rgba(255,255,255,.1);
        .footer-bottom-content {
            padding: 38px 0;

            p {
                margin: 0;

                a {
                    color: $white-color;
                    font-weight: 500;
                }
            }
        }
    }
}